@import "../../theme/colors"
@import "./variables"

.g-orange
	color: $clFlushOrange !important

.g-red
	color: $clCrimson !important

.g-green
	color: $clLimeade !important

.g-white
	color: $clWhite !important

.g-primary-button
	display: flex
	background: $clLimeade
	align-items: center
	text-decoration: none
	color: $clWhite
	padding: 0 16px
	justify-content: center
	border-radius: 8px
	width: 242px
	height: 48px
	border: none

.g-primary-button.disabled
	background: $clLightSilverChalice

.g-secondary-button
	display: flex
	background: $clFlushOrange
	align-items: center
	text-decoration: none
	color: $clWhite
	padding: 0 16px
	justify-content: center
	border-radius: 8px
	width: 242px
	height: 48px
	border: none

.g-tertiary-button
	background: $clWhite
	color: $clLimeade
	width: 242px
	height: 48px
	border-radius: 8px
	font-size: 14px
	font-weight: 500
	letter-spacing: 1px
	border: 1px solid $clLimeade
	outline: none
	cursor: pointer

.g-tertiary-button.disabled
	color: $clLightSilverChalice

.g-subtitle
	color: #485868
	font-family: Heebo
	font-size: 18px
	font-style: normal
	font-weight: 500
	line-height: normal
	letter-spacing: 0.024px

.g-pointer
	cursor: pointer

.g-testimonial-dialog
	width: 600px !important
	overflow: auto
	max-height: 95% !important